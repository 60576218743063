//Tailwind
import "./scss/app.scss";

//Alpine JS
import "alpinejs";

//Highway
import Highway from "@dogstudio/highway";

//Highway Renderers
import HomeRenderer from "./js/highway/renderer/home-renderer";

//Highway Transitions
import Fade from "./js/highway/transition/fade";

import Plyr from "plyr";

const player = new Plyr("#player");

/***
 * Highway
 */

// Call Highway.Core once.
// Store it in a variable to use events
const H = new Highway.Core({
  renderers: {
    homepage: HomeRenderer,
    default: HomeRenderer,
  },
  transitions: {
    name: Fade,
    default: Fade,
  },
});

// Listen Events

H.on("NAVIGATE_END", ({ to }) => {
  // Call Quicklink
  //listen();
});

window.addEventListener("load", () => {
  // Call Quicklink
  //listen();
});
